:root {
    /* ROSES COLORS */
    --pink: #e7007c;
    --pink-hot: #ff007a;
    --pink-light: #ee4e87;
    --pink-alt: #ff4e69;
    --peach: #ff8383;
    --green: #a6cc50;
    --blue: #2081e2;
    --white: #ffffff;
    --ghost: #f9f9f9;
    --chalk: #f4f4f4;
    --dust: #eeeeee;
    --ash: #dddddd;
    --faded: #cccccc;
    --gray-light: #999999;
    --gray: #757575;
    --gray-mid: #555555;
    --gray-dark: #333333;
    --charcoal: #232323;
    --midnight: #1a1a1a;
    --dawn: #111111;
    --black: #000000;
    --orange: #fb4b00;
    /* GRADIENT */
    --g-default: #e7007c;
    --g-inverse: #ff007a;
    /* DEFAULT PAGES */
    --default-color-background: var(--black);
    --default-color-header: var(--white);
    --default-color-text: var(--gray-light);
    --default-color-component: var(--midnight);
    --default-color-error: var(--peach);
    --default-color-success: var(--green);
    /* BREAKPINTS SIZES */
    --bp-sm: 30em;
    --bp-md: 47em;
    --bp-lg: 75em;
    --bp-xlg: 100em;
}
/* FONTS */
/* ROSES FONT - MONTSERRAT */

@font-face {
    font-family: "Montserrat Variable";
    src: local("Montserrat"), local("Montserrat-Variable"),
    url("../assets/fonts/Montserrat-Variable.ttf") format("truetype");
}
/* ROSES FONT - ROBOTO */

@font-face {
    font-family: "Roboto Regular";
    src: local("Roboto"), local("Roboto-Regular"),
    url("../assets/fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto Bold";
    src: local("Roboto"), local("Roboto-Bold"),
    url("../assets/fonts/Roboto-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto Light";
    src: local("Roboto"), local("Roboto-Light"),
    url("../assets/fonts/Roboto-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto Medium";
    src: local("Roboto"), local("Roboto-Medium"),
    url("../assets/fonts/Roboto-Medium.ttf") format("truetype");
}
// Icons
$roses-font-family: "roses-icon" !default;
$roses-font-path: "/assets/fonts/" !default;
$icon-add: unquote('"\\e900"');
$icon-airplane: unquote('"\\e901"');
$icon-apenotic-avatar: unquote('"\\e902"');
$icon-apenotic: unquote('"\\e903"');
$icon-apple: unquote('"\\e904"');
$icon-arrow-down: unquote('"\\e905"');
$icon-arrow-left: unquote('"\\e906"');
$icon-arrow-right: unquote('"\\e907"');
$icon-arrow-up: unquote('"\\e908"');
$icon-auction: unquote('"\\e909"');
$icon-bank: unquote('"\\e90a"');
$icon-birth: unquote('"\\e90b"');
$icon-blocked: unquote('"\\e90c"');
$icon-bookmark: unquote('"\\e90d"');
$icon-brush: unquote('"\\e90e"');
$icon-bulk-images: unquote('"\\e90f"');
$icon-burn: unquote('"\\e910"');
$icon-buy: unquote('"\\e911"');
$icon-calendar-add: unquote('"\\e912"');
$icon-camera: unquote('"\\e913"');
$icon-camp: unquote('"\\e914"');
$icon-cancel: unquote('"\\e915"');
$icon-Cardano: unquote('"\\e916"');
$icon-certified-1: unquote('"\\e917"');
$icon-certified-path1: unquote('"\\e918"');
$icon-certified-path2: unquote('"\\e919"');
$icon-chain: unquote('"\\e91a"');
$icon-check: unquote('"\\e91b"');
$icon-chevron-bottom: unquote('"\\e91c"');
$icon-chevron-double-left: unquote('"\\e91d"');
$icon-chevron-double-right: unquote('"\\e91e"');
$icon-chevron-left: unquote('"\\e91f"');
$icon-chevron-right: unquote('"\\e920"');
$icon-chevron-top: unquote('"\\e921"');
$icon-circle: unquote('"\\e922"');
$icon-clock: unquote('"\\e923"');
$icon-close: unquote('"\\e924"');
$icon-cloud: unquote('"\\e925"');
$icon-code-1: unquote('"\\e926"');
$icon-code: unquote('"\\e927"');
$icon-collectors: unquote('"\\e928"');
$icon-connection: unquote('"\\e929"');
$icon-contract: unquote('"\\e92a"');
$icon-copy: unquote('"\\e92b"');
$icon-credit-card: unquote('"\\e92c"');
$icon-cube: unquote('"\\e92d"');
$icon-dashboard: unquote('"\\e92e"');
$icon-deferred: unquote('"\\e92f"');
$icon-detail: unquote('"\\e930"');
$icon-digitalprint: unquote('"\\e931"');
$icon-discord: unquote('"\\e932"');
$icon-dollar: unquote('"\\e933"');
$icon-dots-h: unquote('"\\e934"');
$icon-dots-v: unquote('"\\e935"');
$icon-dribble: unquote('"\\e936"');
$icon-drink: unquote('"\\e937"');
$icon-earth: unquote('"\\e938"');
$icon-edit: unquote('"\\e939"');
$icon-ethereum: unquote('"\\e93a"');
$icon-ethnicity: unquote('"\\e93b"');
$icon-events: unquote('"\\e93c"');
$icon-eye: unquote('"\\e93d"');
$icon-face-id-confirmed: unquote('"\\e93e"');
$icon-face-id: unquote('"\\e93f"');
$icon-filter: unquote('"\\e940"');
$icon-fingerprint: unquote('"\\e941"');
$icon-fixed: unquote('"\\e942"');
$icon-flag: unquote('"\\e943"');
$icon-folder: unquote('"\\e944"');
$icon-food-1: unquote('"\\e945"');
$icon-food-2: unquote('"\\e946"');
$icon-food: unquote('"\\e947"');
$icon-games: unquote('"\\e948"');
$icon-gas: unquote('"\\e949"');
$icon-gear: unquote('"\\e94a"');
$icon-gender: unquote('"\\e94b"');
$icon-gifts: unquote('"\\e94c"');
$icon-globe: unquote('"\\e94d"');
$icon-google-play-path1: unquote('"\\e94e"');
$icon-google-play-path2: unquote('"\\e94f"');
$icon-google-play-path3: unquote('"\\e950"');
$icon-google-play-path4: unquote('"\\e951"');
$icon-Google-path1: unquote('"\\e952"');
$icon-Google-path2: unquote('"\\e953"');
$icon-Google-path3: unquote('"\\e954"');
$icon-Google-path4: unquote('"\\e955"');
$icon-grid: unquote('"\\e956"');
$icon-gym: unquote('"\\e957"');
$icon-hair-1: unquote('"\\e958"');
$icon-hair-2: unquote('"\\e959"');
$icon-hair: unquote('"\\e95a"');
$icon-heart-outline: unquote('"\\e95b"');
$icon-heart-solid: unquote('"\\e95c"');
$icon-height: unquote('"\\e95d"');
$icon-hiking: unquote('"\\e95e"');
$icon-home: unquote('"\\e95f"');
$icon-id: unquote('"\\e960"');
$icon-info: unquote('"\\e961"');
$icon-instagram: unquote('"\\e962"');
$icon-key: unquote('"\\e963"');
$icon-keypad: unquote('"\\e964"');
$icon-language: unquote('"\\e965"');
$icon-layers: unquote('"\\e966"');
$icon-link: unquote('"\\e967"');
$icon-linkedin: unquote('"\\e968"');
$icon-loan: unquote('"\\e969"');
$icon-location: unquote('"\\e96a"');
$icon-lock: unquote('"\\e96b"');
$icon-log-in: unquote('"\\e96c"');
$icon-log-out: unquote('"\\e96d"');
$icon-mail: unquote('"\\e96e"');
$icon-makeup-1: unquote('"\\e96f"');
$icon-makeup-2: unquote('"\\e970"');
$icon-makeup: unquote('"\\e971"');
$icon-marketplace: unquote('"\\e972"');
$icon-match: unquote('"\\e973"');
$icon-measurement-1: unquote('"\\e974"');
$icon-measurement-2: unquote('"\\e975"');
$icon-measurement: unquote('"\\e976"');
$icon-menu: unquote('"\\e977"');
$icon-messages: unquote('"\\e978"');
$icon-mic: unquote('"\\e979"');
$icon-mint: unquote('"\\e97a"');
$icon-minus: unquote('"\\e97b"');
$icon-mobile-ring: unquote('"\\e97c"');
$icon-mobile: unquote('"\\e97d"');
$icon-money: unquote('"\\e97e"');
$icon-moon: unquote('"\\e97f"');
$icon-move: unquote('"\\e980"');
$icon-music: unquote('"\\e981"');
$icon-nft-single: unquote('"\\e982"');
$icon-nft: unquote('"\\e983"');
$icon-notebook: unquote('"\\e984"');
$icon-notes: unquote('"\\e985"');
$icon-notification: unquote('"\\e986"');
$icon-notifiication-add: unquote('"\\e987"');
$icon-ownership: unquote('"\\e988"');
$icon-passanger: unquote('"\\e989"');
$icon-passport-1: unquote('"\\e98a"');
$icon-passport: unquote('"\\e98b"');
$icon-patreon: unquote('"\\e98c"');
$icon-percent: unquote('"\\e98d"');
$icon-perfume: unquote('"\\e98e"');
$icon-phone: unquote('"\\e98f"');
$icon-photo: unquote('"\\e990"');
$icon-play: unquote('"\\e991"');
$icon-plus: unquote('"\\e992"');
$icon-private: unquote('"\\e993"');
$icon-public: unquote('"\\e994"');
$icon-puzzle: unquote('"\\e995"');
$icon-qr-code: unquote('"\\e996"');
$icon-qr-scanner: unquote('"\\e997"');
$icon-question: unquote('"\\e998"');
$icon-race: unquote('"\\e999"');
$icon-refresh: unquote('"\\e99a"');
$icon-reorder: unquote('"\\e99b"');
$icon-retweet: unquote('"\\e99c"');
$icon-revenue: unquote('"\\e99d"');
$icon-roadmap: unquote('"\\e99e"');
$icon-robot: unquote('"\\e99f"');
$icon-roses-default-path1: unquote('"\\e9a0"');
$icon-roses-default-path2: unquote('"\\e9a1"');
$icon-roses-outline: unquote('"\\e9a2"');
$icon-roses-solid: unquote('"\\e9a3"');
$icon-search: unquote('"\\e9a4"');
$icon-security: unquote('"\\e9a5"');
$icon-selfie: unquote('"\\e9a6"');
$icon-send-1: unquote('"\\e9a7"');
$icon-send: unquote('"\\e9a8"');
$icon-share-1: unquote('"\\e9a9"');
$icon-share: unquote('"\\e9aa"');
$icon-smile: unquote('"\\e9ab"');
$icon-solana: unquote('"\\e9ac"');
$icon-sport: unquote('"\\e9ad"');
$icon-star-outline: unquote('"\\e9ae"');
$icon-star-solid: unquote('"\\e9af"');
$icon-stopwatch: unquote('"\\e9b0"');
$icon-text: unquote('"\\e9b1"');
$icon-theatre: unquote('"\\e9b2"');
$icon-tickets: unquote('"\\e9b3"');
$icon-tiktok: unquote('"\\e9b4"');
$icon-tips: unquote('"\\e9b5"');
$icon-transfer: unquote('"\\e9b6"');
$icon-trash: unquote('"\\e9b7"');
$icon-twitter: unquote('"\\e9b8"');
$icon-underwear: unquote('"\\e9b9"');
$icon-unlock: unquote('"\\e9ba"');
$icon-upload-bulk: unquote('"\\e9bb"');
$icon-upload-1: unquote('"\\e9bc"');
$icon-upload: unquote('"\\e9bd"');
$icon-user: unquote('"\\e9be"');
$icon-verified-1: unquote('"\\e9bf"');
$icon-verified-path1: unquote('"\\e9c0"');
$icon-verified-path2: unquote('"\\e9c1"');
$icon-video: unquote('"\\e9c2"');
$icon-wallet: unquote('"\\e9c3"');
$icon-weight-1: unquote('"\\e9c4"');
$icon-weight-2: unquote('"\\e9c5"');
$icon-weight: unquote('"\\e9c6"');
$icon-whitelist: unquote('"\\e9c7"');
$icon-wi-fi-off: unquote('"\\e9c8"');
$icon-wi-fi-on: unquote('"\\e9c9"');
$icon-zodiac: unquote('"\\e9ca"');

.montserrat {
    font-family: "Montserrat Variable";
}

.roboto-regular {
    font-family: "Roboto Regular";
}

.roboto-bold {
    font-family: "Roboto Bold";
}
// MARGIN

.no-margin {
    margin: 0;
}

.margin-4 {
    margin: 4px;
}

.margin-8 {
    margin: 8px;
}

.margin-16 {
    margin: 16px;
}

.margin-24 {
    margin: 24px;
}

.margin-40 {
    margin: 40px;
}
// Vertical Margin

.margin-vert-4 {
    margin: 4px 0;
}

.margin-vert-8 {
    margin: 8px 0;
}

.margin-vert-16 {
    margin: 16px 0;
}

.margin-vert-24 {
    margin: 24px 0;
}

.margin-vert-40 {
    margin: 40px 0;
}
// Horizontal Margin

.margin-hor-4 {
    margin: 0 4px;
}

.margin-hor-8 {
    margin: 0 8px;
}

.margin-hor-16 {
    margin: 0 16px;
}

.margin-hor-24 {
    margin: 0 24px;
}

.margin-hor-40 {
    margin: 0 40px;
}
// Left Margin

.margin-left-4 {
    margin-left: 4px;
}

.margin-left-8 {
    margin-left: 8px;
}

.margin-left-16 {
    margin-left: 16px;
}

.margin-left-24 {
    margin-left: 24px;
}

.margin-left-40 {
    margin-left: 40px;
}
// Right Margin

.margin-right-4 {
    margin-right: 4px;
}

.margin-right-8 {
    margin-right: 8px;
}

.margin-right-16 {
    margin-right: 16px;
}

.margin-right-24 {
    margin-right: 24px;
}

.margin-right-40 {
    margin-right: 40px;
}
// Top Margin

.margin-top-4 {
    margin-top: 4px;
}

.margin-top-8 {
    margin-top: 8px;
}

.margin-top-16 {
    margin-top: 16px;
}

.margin-top-24 {
    margin-top: 24px;
}

.margin-top-40 {
    margin-top: 40px;
}
// Bottom Margin

.margin-bottom-4 {
    margin-bottom: 4px;
}

.margin-bottom-8 {
    margin-bottom: 8px;
}

.margin-bottom-16 {
    margin-bottom: 16px;
}

.margin-bottom-24 {
    margin-bottom: 24px;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}
// PADDING

.no-padding {
    padding: 0;
}

.padding-4 {
    padding: 4px;
}

.padding-8 {
    padding: 8px;
}

.padding-16 {
    padding: 16px;
}

.padding-24 {
    padding: 24px;
}

.padding-40 {
    padding: 40px;
}
// Vertical Padding

.padding-vert-4 {
    padding: 4px 0;
}

.padding-vert-8 {
    padding: 8px 0;
}

.padding-vert-16 {
    padding: 16px 0;
}

.padding-vert-24 {
    padding: 24px 0;
}

.padding-vert-40 {
    padding: 40px 0;
}
// Horizontal Padding

.padding-hor-4 {
    padding: 0 4px;
}

.padding-hor-8 {
    padding: 0 8px;
}

.padding-hor-16 {
    padding: 0 16px;
}

.padding-hor-24 {
    padding: 0 24px;
}

.padding-hor-40 {
    padding: 0 40px;
}
// Left Padding

.padding-left-4 {
    padding-left: 4px;
}

.padding-left-8 {
    padding-left: 8px;
}

.padding-left-16 {
    padding-left: 16px;
}

.padding-left-24 {
    padding-left: 24px;
}

.padding-left-40 {
    padding-left: 40px;
}
// Right Padding

.padding-right-4 {
    padding-right: 4px;
}

.padding-right-8 {
    padding-right: 8px;
}

.padding-right-16 {
    padding-right: 16px;
}

.padding-right-24 {
    padding-right: 24px;
}

.padding-right-40 {
    padding-right: 40px;
}
// Top Padding

.padding-top-4 {
    padding-top: 4px;
}

.padding-top-8 {
    padding-top: 8px;
}

.padding-top-16 {
    padding-top: 16px;
}

.padding-top-24 {
    padding-top: 24px;
}

.padding-top-40 {
    padding-top: 40px;
}
// Bottom Padding

.padding-bottom-4 {
    padding-bottom: 4px;
}

.padding-bottom-8 {
    padding-bottom: 8px;
}

.padding-bottom-16 {
    padding-bottom: 16px;
}

.padding-bottom-24 {
    padding-bottom: 24px;
}

.padding-bottom-40 {
    padding-bottom: 40px;
}

.background-blur-gray {
    background-color: rgba(255, 255, 255, 0.15);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
}

.background-blur-pink {
    background-color: #ff007a26;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
}

.icon {
    &:before,

    &:after {
        color: var(--pink-hot);
    }

    &.icon-sm {
        font-size: 16px;
    }

    &.icon-md {
        font-size: 24px;
    }

    &.icon-lg {
        font-size: 32px;
    }

    &.icon-xlg {
        font-size: 40px;
    }

    &.icon-match {
        &:before {
            color: var(--default-color-success);
        }
    }

    &.icon-gray {
        &:before {
            color: var(--gray-mid);
        }
    }

    &.icon-remove {
        &:before {
            color: var(--peach);
        }
    }
}
