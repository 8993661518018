.home-page {
    #link-list {
        margin: 0 auto;
        max-width: 600px;
    }

    .banner {
        position: relative;

        img {
            width: 100%;
            height: 300px;
            object-fit: cover;
        }

        .logo-wrapper {
            position: absolute;
            z-index: 3;
            bottom: 50%;
            left: 50%;
            display: flex;
            flex-direction: row;
            transform: translate(-50%, 50%);

            .logo {
                width: auto;
                height: 24px;
            }

            .tm {
                margin-top: 7px;
                margin-left: 2px;
                line-height: 0;
            }
        }

        &::before {
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            content: "";
            background: radial-gradient(circle at top left, #e98843, transparent 55%),
            radial-gradient(circle at top right, #4c726e, transparent 55%);
        }

        &::after {
            position: absolute;
            z-index: 2;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            content: "";
            background: linear-gradient(
            to top,
            rgba(0, 0, 0, 1) 0px,
            rgba(0, 0, 0, 0.5) 60px,
            rgba(0, 0, 0, 0) 300px
            );
        }
    }
}
//E98843
