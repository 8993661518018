html,

html * {
    margin: 0;
    padding: 0;
    font-family: "Roboto", "Helvetica Neue", "Arial", sans-serif;
    box-sizing: border-box;
    scrollbar-width: none;

    ::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
}

body {
    position: relative;
    font-family: "Roboto Regular";
    font-size: 16px;
    overflow-x: hidden;
    background: var(--default-color-background);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.4;
    h1,
    h2,
    h3,
    h4,
    h5,

    h6 {
        margin: 16px 0;
        color: var(--default-color-header);
        font-family: "Roboto Regular";
        font-weight: 300;
        text-transform: none;

        strong {
            font-weight: 600;
        }

        span {
            color: var(--pink-hot);
            font-size: 1rem;
            //display: block;
        }

        &.no-margin-top {
            margin: 0 0 16px 0;
        }
    }

    h1 {
        font-size: 1.5rem;
        //24px
        line-height: 1.2;
    }

    h2 {
        font-size: 2rem;
        //32px
        line-height: 1.2;
    }

    h3 {
        font-size: 1.75rem;
        //28px
        line-height: 1.4;

        &.header {
            font-size: 1.5rem;
            //24px
        }
    }

    h4 {
        font-size: 1.25rem;
        //20px
        line-height: 1.4;
    }

    h5 {
        font-size: 1rem;
        //16px
        line-height: 1.4;
    }

    h6 {
        font-size: 0.875rem;
        //14px
        line-height: 1.4;
    }

    hr {
        margin: 40px auto;
        border: none;
        outline: none;
        border-bottom: 1px solid var(--gray-dark);
        max-width: 600px;

        &.white {
            border-bottom: 1px solid var(--white);
        }

        &.pink {
            border-bottom: 1px solid var(--pink-hot);
        }

        &.orange {
            border-bottom: 1px solid var(--orange);
        }
    }

    span {
        line-height: 1.4;

        &.highlight {
            margin-left: 4px;
            color: var(--pink-hot);
        }

        &.disclaimer {
            margin-top: 8px;
            color: var(--gray);
            font-size: 0.687rem;
            font-style: italic;

            &.right {
                justify-self: flex-end;
            }

            &.left {
                justify-self: flex-start;
            }
        }

        &.time {
            color: var(--gray);
            font-size: 0.75rem;
        }
    }

    p {
        margin: 8px 0;
        color: var(--default-color-text);
        font-family: "Roboto Regular";
        font-size: 1rem;
        //16px
        line-height: 1.4;

        &.tm {
            color: var(--white);
        }

        &.small-p {
            font-size: 0.875rem;
            //14px
        }

        &.white-p {
            color: var(--white);
        }

        &.profile-info {
            color: var(--white);
            font-family: "Montserrat Variable";
            font-weight: 400;

            &.small {
                font-size: 1rem;
            }

            span {
                margin-left: 4px;
            }
        }

        &.margin-bottom {
            margin-bottom: 16px;
        }

        &.text-gray {
            color: var(--gray);
        }

        &.message {
            font-size: 0.875rem;
            word-break: break-word;
        }

        span {
            &.text-highlight {
                color: var(--gray-light);
            }

            &.text-highlight--white {
                color: var(--white);
            }

            &.photo-highlight {
                color: var(--pink-hot);
            }

            &.referral-code {
                padding-left: 4px;
                color: var(--white);
            }
        }

        &.description {
            color: var(--gray);
            font-size: 0.875rem;
            //14px
        }

        &.caption {
            position: relative;
            margin: 24px auto;
            display: block;
            padding: 0 0 0 32px;
            color: var(--gray);
            font-size: 0.75rem;
            //12px
            text-align: left;

            &.no-padding {
                padding-left: 0;
            }

            &.small-padding {
                padding-left: 16px;
            }

            &.tiny-margin {
                margin: 12px 0;
            }

            .icon {
                position: absolute;
                top: 0;
                left: 0;

                &:before {
                    color: var(--gray);
                }
            }
        }

        &.description {
            margin-top: 4px;
            color: var(--gray-light);
            font-size: 0.75rem;
            //12px
        }

        &.disclaimer {
            border-radius: 8px;
            display: inline-block;
            padding: 8px 12px;
            color: var(--gray);
            font-size: 0.75em;
            background: rgba(0, 0, 0, 0.5);
        }
    }
    b,

    strong {
        font-weight: 600;
    }

    ul {
        padding: 16px 0;

        li {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 16px 0;
            gap: 16px;
            border-bottom: 1px solid var(--gray-dark);
            background: var(--black) url("../assets/icons/arrows/chevron-right.svg")
            no-repeat 99% 50%;
            cursor: pointer;

            img {
                border: 1px solid var(--midnight);
                border-radius: 8px;
                width: 50px;
                height: 50px;
                object-fit: cover;
            }

            &:last-child {
                border-bottom: none;
            }

            a {
                width: 100%;
                display: flex;
                justify-content: row;
                align-items: center;
                color: var(--white);
                font-family: "Roboto Regular";
                font-size: 0.85rem;
                gap: 16px;
                background: var(--black) url("../assets/icons/arrows/chevron-right.svg")
                no-repeat 99% 50%;
                // 16px
                text-decoration: none;
                cursor: pointer;

                strong {
                    margin-right: -12px;
                }

                p {
                    margin: 0;
                }
            }
        }
    }

    footer {
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
            text-align: center;
        }

        .logo-wrapper {
            z-index: 3;
            display: flex;
            flex-direction: row;
            scale: 0.5;

            .logo {
                width: auto;
                height: 24px;
            }

            .tm {
                margin-top: 7px;
                margin-left: 2px;
                line-height: 0;
            }
        }
    }
}
